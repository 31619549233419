// ChildComponent.jsx
import React from 'react';

const ChildComponent = ({ content }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default ChildComponent;
