import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const ProductInfo = ({ productInfo }) => {
  const highlightStyle = {
    color: "#d0121a",
    fontWeight: "bold",
  };

  const renderDescription = () => {
    if (!productInfo.desc) {
      return null;
    }

    const description = productInfo.desc.split(/:(.*?)-/).map((part, index) => (
      <span key={index} style={index % 2 === 1 ? highlightStyle : {}}>
        {part}
      </span>
    ));

    return <>{description}</>;
  };

  const renderAdditionalInfo = (info) => {
    return Object.entries(info).map(([key, value]) => (
      <div key={key} className="mb-2">
        <p className="font-medium text-base">{key}</p>
        {Array.isArray(value) ? (
          <ul className="list-disc list-inside pl-4">
            {value.map((item, index) => (
              <li key={index} className="text-gray-700">{item}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-700">{value}</p>
        )}
      </div>
    ));
  };

  // State to manage selected options for dynamic dropdowns
  const [selectedOptions, setSelectedOptions] = useState(
    Object.keys(productInfo.more_info || {}).reduce((acc, key) => {
      acc[key] = (productInfo.more_info[key] && productInfo.more_info[key][0]) || "";
      return acc;
    }, {})
  );

  const handleDropdownChange = (key, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-5 my-4">
      <h2 className="text-4xl font-semibold">{productInfo.title}</h2>
      <p className="text-2xl font-semibold">
        {productInfo.price} Dt
        {/* <span className="text-xl font-semibold line-through ml-2">540</span> */}
        <span className="text-xs ml-2 inline-flex items-center px-3 py-1 mb-2 rounded-full bg-green-600 text-white">
          {productInfo.varients }
        </span>
      </p>
      <hr />
      {/* <p className="text-base text-gray-600">{renderDescription()}</p> */}



      <div className="">
       
        {productInfo.more_info && (
          <div className="mt-0">
            {Object.entries(productInfo.more_info).map(([key, values]) => (
              <div key={key} className="mb-4">
                <label className="block font-medium text-base">{key}</label>
                <select
                  value={selectedOptions[key] || ""}
                  onChange={(e) => handleDropdownChange(key, e.target.value)}
                  className="w-full py-2 px-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {values.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        )}
      </div>
      <button
        onClick={() => {
          console.log(productInfo)
          dispatch(
            addToCart({
              _id: productInfo._id,
              name: productInfo.title,
              quantity: 1,
              image: productInfo.images[0], // Assuming you want to use the first image as the default image
              badge: productInfo.badge,
              price: productInfo.price,
              colors: selectedOptions["Color:"], // Use the selected color
            })
          )
        }
        
        }
        className="w-full py-4 bg-blue-500 hover:bg-blue-600 duration-300 text-white text-lg font-titleFont"
      >
        Add to Cart
      </button>
    </div>
  );
};

export default ProductInfo;
