import React from 'react';

const ProductDetailsShimmer = () => {
  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          {/* Shimmer for Breadcrumbs */}
          <div className="w-1/3 h-6 bg-gray-200 animate-pulse rounded mb-4"></div>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="xl:col-span-2 flex flex-col" style={{ height: "500px" }}>
            {/* Shimmer for Image Carousel */}
            <div className="w-full h-full bg-gray-200 animate-pulse rounded"></div>
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
            {/* Shimmer for Product Info */}
            <div className="w-3/4 h-6 bg-gray-200 animate-pulse rounded mb-2"></div>
            <div className="w-1/2 h-6 bg-gray-200 animate-pulse rounded mb-2"></div>
            <div className="w-full h-6 bg-gray-200 animate-pulse rounded mb-2"></div>
            <div className="w-full h-6 bg-gray-200 animate-pulse rounded mb-2"></div>
          </div>
        </div>
        <div>
          <div className="space-x-4 pt-4">
            {/* Shimmer for Tabs */}
            <div className="inline-block w-24 h-8 bg-gray-200 animate-pulse rounded mr-2"></div>
            <div className="inline-block w-24 h-8 bg-gray-200 animate-pulse rounded"></div>
          </div>
          <div className="my-4">
            {/* Shimmer for Tab Content */}
            <div className="w-full h-32 bg-gray-200 animate-pulse rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsShimmer;
