import React, { Suspense, lazy } from 'react';
import BannerBottom from '../../components/Banner/BannerBottom';
import Sale from "../../components/home/Sale/Sale";

const NewArrivals = lazy(() => import('../../components/home/NewArrivals/NewArrivals'));

const YouTubeReels = () => (
  <div className="youtube-reels">
    <h2 className="text-2xl font-bold my-4">Featured Product Reels</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/5pf0yiLiA-U"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/obXYUMvuzII"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <div className="mt-4 flex justify-center">
      <a
        href="https://www.youtube.com/@ridex_001?si=bfxhQqcrPyZlks9i"
        target="_blank"
        rel="noopener noreferrer"
        className="px-6 py-2 bg-primeColor text-white font-semibold rounded-md hover:bg-black duration-300"
      >
        Visit our channel for more
      </a>
    </div>
  </div>
);

const Home = () => {
  return (
    <div className="w-full mx-auto">
      <div className="max-w-container mx-auto px-4">
        <Suspense fallback={<div>Loading...</div>}>
          <NewArrivals heading="Featured Saddles" />
          <br />
          <NewArrivals heading="Featured Bridles" />
          <br />
          <NewArrivals heading="Leather Goods" />
          <br />
          <NewArrivals heading="Featured Headstall" />
        </Suspense>
        
        <YouTubeReels />
        <Sale />
        <BannerBottom />
        {/* <BestSellers /> */}
        {/* <YearProduct /> */}
        {/* <SpecialOffers /> */}
      </div>
    </div>
  );
};

export default Home;
