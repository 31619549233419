import { getDocs,getDoc, doc ,collection, query, where, limit, startAfter } from "firebase/firestore";
import { getDb } from "../firebase";
import { openDB } from 'idb';

const collection_name = "products";
const pageSize = 300; // Number of documents per page

const dbPromise = openDB('my-database', 1, {
  upgrade(db) {
    db.createObjectStore('keyval');
  },
});

const set = async (key, val) => {
  const db = await dbPromise;
  await db.put('keyval', val, key);
};

const get = async (key) => {
  const db = await dbPromise;
  return db.get('keyval', key);
};



export const fetchProductByCustomId = async (customId) => {
    const db = getDb();
    const collectionRef = collection(db, "products");
    const q = query(collectionRef, where("id", "==", customId));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]; // Assuming customId is unique and we take the first result
      return { id: doc.id, ...doc.data() };
    } else {
      console.log("No such document with customId!");
      return null;
    }
  };

export const findAll = async (search, page = 1) => {
    console.log("search", search)
    const cacheKey = JSON.stringify({ search, page });
    const currentTime = Date.now();

    // Check cache
    const cache = await get('product_cache') || {};
    if (Object.keys(search).length > 0 && cache[cacheKey] && (currentTime - cache[cacheKey].timestamp < 24 * 60 * 60 * 1000)) {
        // alert("cached")
        return cache[cacheKey].data;
    }

    const db = getDb();
    const collectionRef = collection(db, collection_name);

    let docQuery = query(collectionRef, limit(pageSize));

    console.log(docQuery)
    // Apply search filters
    if (search) {
        Object.keys(search).forEach(key => {
            if (key.includes(".")) {
                docQuery = query(docQuery, where(key, 'array-contains', search[key]));
            } else {
                docQuery = query(docQuery, where(key, '==', search[key]));
            }
        });
    }

    const docSnapshot = await getDocs(docQuery);
    console.log(docSnapshot)
    const res = [];
    docSnapshot.forEach(doc => {
        res.push({
            id: doc.id,
            ...doc.data()
        });
    });

    // Store the result in IndexedDB
    if (Object.keys(search).length > 0) {
        cache[cacheKey] = {
            data: res,
            timestamp: currentTime
        };
        await set('product_cache', cache);
    }

    return res;
};
