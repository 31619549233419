import React, { useState, useEffect } from "react";
import { getAuthentication, getDb } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";

const Profile = () => {
  const [userDetails, setUserDetails] = useState({
    clientName: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    city: "",
    country: "",
    zip: "",
  });
  const [editing, setEditing] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const user = getAuthentication().currentUser;

  useEffect(() => {
    if (!user) {
      // Redirect to login page if user is not authenticated
      navigate("/signin");
    } else {
      const fetchUserDetails = async () => {
        try {
          const userDoc = doc(getDb(), "users", user.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            setUserDetails(docSnap.data());
          } else {
            setErrorMsg("No user data found.");
          }
        } catch (error) {
          setErrorMsg("Error fetching user data.");
        }
      };
      fetchUserDetails();
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const userDoc = doc(getDb(), "users", user.uid);
      await updateDoc(userDoc, userDetails);
      setSuccessMsg("Profile updated successfully.");
      setEditing(false);
    } catch (error) {
      setErrorMsg("Error updating profile. Please try again.");
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center px-6 py-4">
        <h1 className="font-titleFont text-2xl font-semibold mb-4">
          {editing ? "Edit Profile" : "View Profile"}
        </h1>
        {successMsg && (
          <p className="text-green-500 font-medium mb-4">{successMsg}</p>
        )}
        {errorMsg && (
          <p className="text-red-500 font-medium mb-4">{errorMsg}</p>
        )}
        <form className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Full Name</label>
            <input
              name="clientName"
              value={userDetails.clientName}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="text"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Email</label>
            <input
              name="email"
              value={userDetails.email}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="email"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Phone Number</label>
            <input
              name="phone"
              value={userDetails.phone}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="text"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Password</label>
            <input
              name="password"
              value={userDetails.password}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="password"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Address</label>
            <input
              name="address"
              value={userDetails.address}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="text"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">City</label>
            <input
              name="city"
              value={userDetails.city}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="text"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Country</label>
            <input
              name="country"
              value={userDetails.country}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="text"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-semibold text-gray-600">Zip Code</label>
            <input
              name="zip"
              value={userDetails.zip}
              onChange={handleChange}
              disabled={!editing}
              className="p-2 border border-gray-400 rounded"
              type="text"
            />
          </div>
          <div className="flex gap-4 mt-4">
            {editing ? (
              <>
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-blue-500 text-white p-2 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setEditing(false)}
                  className="bg-gray-500 text-white p-2 rounded"
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={() => setEditing(true)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Edit
              </button>
            )}
          </div>
        </form>
        <Link to="/" className="mt-4 text-blue-500">Back to Home</Link>
      </div>
    </div>
  );
};

export default Profile;
