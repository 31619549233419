import React from "react";

const SuccessModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 w-96 text-center">
        <h2 className="text-xl font-semibold mb-4">Order Placed Successfully!</h2>
        <p className="mb-4">
          Your order has been placed successfully. Our team will contact you for the next steps.
        </p>
        <button
          onClick={onClose}
          className="bg-primeColor text-white px-4 py-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
