import React from 'react';

const PaginationShimmer = ({ itemsPerPage }) => {
  const shimmerItems = Array.from({ length: itemsPerPage }, (_, index) => (
    <div key={index} className="w-full">
      <div className="bg-gray-200 animate-pulse rounded-lg shadow-md p-4">
        <div className="w-full h-32 bg-gray-300 rounded mb-4"></div>
        <div className="w-3/4 h-6 bg-gray-300 rounded mb-2"></div>
        <div className="w-1/2 h-6 bg-gray-300 rounded"></div>
      </div>
    </div>
  ));

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 mdl:gap-4 lg:gap-10">
        {shimmerItems}
      </div>
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        <div className="flex text-base font-semibold font-titleFont py-10">
          <div className="w-9 h-9 border-[1px] border-lightColor bg-gray-200 animate-pulse rounded mr-6"></div>
          <div className="w-9 h-9 border-[1px] border-lightColor bg-gray-200 animate-pulse rounded mr-6"></div>
          <div className="w-9 h-9 border-[1px] border-lightColor bg-gray-200 animate-pulse rounded"></div>
        </div>
        <p className="text-base font-normal text-lightText">
          Loading products...
        </p>
      </div>
    </div>
  );
};

export default PaginationShimmer;
