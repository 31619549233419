// src/components/ImageCarousel.js

import React, { useState } from 'react';

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="relative w-full h-full">
            <img
                className="w-full h-full object-scale-down rounded-md"
                src={images[currentIndex]}
                alt={`Image ${currentIndex}`}
            />
            <button
                onClick={handlePrevClick}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none"
            >
                &#9664;
            </button>
            <button
                onClick={handleNextClick}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg focus:outline-none"
            >
                &#9654;
            </button>
            <div >
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2" style={{maxWidth:"200px",overflow:"hidden"}}>
                    {images.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-400'
                                }`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImageCarousel;
