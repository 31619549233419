import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { useSelector } from "react-redux";
import { paginationItems } from "../../../constants";
import { findAll } from "../../../services/products.service";
import PaginationShimmer from "../../PagginationShimmer";

function Items({ currentItems, selectedBrands, selectedCategories }) {
  const filteredItems = currentItems.filter((item) => {
    const isBrandSelected =
      selectedBrands.length === 0 ||
      selectedBrands.some((brand) => brand.title === item.brand);

    const isCategorySelected =
      selectedCategories.length === 0 ||
      selectedCategories.some((category) => category.title === item.cat);

    return isBrandSelected && isCategorySelected;
  });

  return (
    <>
      {filteredItems.map((item) => (
        <div key={item._id} className="w-full">
          <Product
            _id={item._id}
            img={item.img}
            productName={item.productName}
            price={item.price}
            color={item.color}
            badge={item.badge}
            title={item.title}
            varients={item.varients}
            images={item.images}
            more_info={item.more_info}
            desc={item.desc}
            about={item.about}
          />
        </div>
      ))}
    </>
  );
}

const Pagination = ({ itemsPerPage, search }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(0);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const res = await findAll(search);

    const res2 = res.map((item) => {
      return {
        _id: item.id,
        img: item.thumbnail,
        productName: item.title,
        price: item.price,
        color: item.varients,
        badge: false,
        brand: "Ridex",
        more_info: item.more_info,
        des: "-",
        cat: "-",
        pdf: "",
        title: item.title,
        images: item.images,
        desc: item.desc,
        about: item.about,
        ficheTech: [
          ...Object.keys(item.more_info).map((key) => {
            return { label: key, value: item.more_info[key].join(", \n") };
          }),
        ],
      };
    });

    setItems([...res2]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const selectedBrands = useSelector(
    (state) => state.orebiReducer.checkedBrands
  );
  const selectedCategories = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    const newStart = newOffset + 1; // Adjust the start index

    setItemOffset(newOffset);
    setItemStart(newStart);
  };

  return (
    <div>
      {loading ? (
        <PaginationShimmer itemsPerPage={itemsPerPage} />
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 mdl:gap-4 lg:gap-10">
            <Items
              currentItems={currentItems}
              selectedBrands={selectedBrands}
              selectedCategories={selectedCategories}
            />
          </div>
          <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
            <ReactPaginate
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel=""
              pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
              pageClassName="mr-6"
              containerClassName="flex text-base font-semibold font-titleFont py-10"
              activeClassName="bg-black text-white"
            />
            <p className="text-base font-normal text-lightText">
              Products from {itemStart} to {Math.min(endOffset, items.length)} of{" "}
              {items.length}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;
