import React from "react";

// List of predefined gradient classes
const gradients = [
  "from-pink-500 via-purple-500 to-indigo-500",
  "from-yellow-400 via-red-500 to-pink-500",
  "from-blue-500 to-teal-500",
  "from-green-400 to-blue-500",
  "from-red-400 via-yellow-400 to-green-400",
  "from-gray-700 to-gray-900",
];

// Function to select a random gradient class
const getRandomGradient = () => {
  const randomIndex = Math.floor(Math.random() * gradients.length);
  return gradients[randomIndex];
};

const Heading = ({ heading }) => {
  const gradientClass = `bg-gradient-to-r ${getRandomGradient()}`;
  
  return (
    <div className={`pt-2 my-2 px-4 pb-2 text-2xl font-bold text-white ${gradientClass} rounded-lg shadow-lg font-serif`}>
      {heading}
    </div>
  );
};

export default Heading;
