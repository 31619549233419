import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Payment = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Payment" />
      <div className="pb-10">
        <p className="text-lg font-medium text-gray-800">
          Thank you for your order! Your order has been successfully received.
        </p>
        <p className="text-lg font-medium text-gray-800 mt-4">
          Our team will contact you shortly to process the payment and provide further details.
        </p>
        <Link to="/">
          <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300">
            Explore More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Payment;
