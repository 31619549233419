import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/pageProps/Breadcrumbs';
import ProductInfo from '../../components/pageProps/productDetails/ProductInfo';
import ImageCarousel from './Carousel';
import ChildComponent from './HtmlChild';
import { fetchProductByCustomId } from '../../services/products.service';
import ProductDetailsShimmer from '../../components/ProductDetailsShimmer';

const tabs = [
  {
    id: 'Info',
    label: 'Info',
  },
  {
    id: 'Description',
    label: 'Description',
  },
];

const ProductDetails = () => {
  const location = useLocation();
  const { _id } = useParams();
  const [prevLocation, setPrevLocation] = useState('');
  const [productInfo, setProductInfo] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const getProductInfo = async () => {
      if (location.state && location.state.item) {
        setProductInfo(location.state.item);
      } else {
        const product = await fetchProductByCustomId(_id);
        setProductInfo(product);
      }
      setPrevLocation(location.pathname);
    };

    getProductInfo();
  }, [location, _id]);

  if (!productInfo) {
    return <ProductDetailsShimmer />;
  }

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          <div className="xl:col-span-2 flex flex-col" style={{ height: "500px" }}>
            {/* Image Carousel */}
            <ImageCarousel images={productInfo.images || []} />
          </div>
          <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>
        <div>
          <div className="space-x-4 pt-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`${
                  activeTab === tab.id
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-800'
                } py-2 px-4 focus:outline-none`}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="my-4">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={activeTab === tab.id ? '' : 'hidden'}
              >
                {tab.id === 'Info' && productInfo.about ? (
                  <div>
                    <table className="table-auto w-full">
                      <tbody>
                        {Object.keys(productInfo.about).map((key) => (
                          <tr key={key} className="bg-gray-100">
                            <td className="border px-4 py-2 font-semibold">{key}</td>
                            <td className="border px-4 py-2">{productInfo.about[key]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : tab.id === 'Description' && productInfo.desc ? (
                  <p>
                    <ChildComponent content={productInfo.desc} />
                  </p>
                ) : (
                  <p>{tab.content}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
