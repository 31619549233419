import React from "react";
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";

const Footer = () => {
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
          <FooterListTitle title="Contact Us" />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
              Feel free to reach out to us for any inquiries or support.
            </p>
            <p className="text-lg mt-2">
              Email us at: <a href="mailto:sanu.ali3281@gmail.com" className="text-primeColor">sanu.ali3281@gmail.com</a>
            </p>
            <p className="text-lg">
              Call us at: <a href="tel:+91 92366 47419" className="text-primeColor">+91 92366 47419</a>
            </p>
            <ul className="flex items-center gap-2 mt-4">
              <a
                href="https://youtube.com/@ridex_001?si=bfxhQqcrPyZlks9i"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaYoutube />
                </li>
              </a>
              <a
                href="https://www.facebook.com/ridex.hides?mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a
                href="https://instagram.com/ridex_saddlery_horse/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
